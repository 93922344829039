import moment from 'moment'
import ZbInfoModal from './zb-info-modal'
import ZbMixinOrdersActive from './ZbMixinOrdersActive'
import ZbMixinOrdersCompleted from './ZbMixinOrdersCompleted'
import ZbMixinOrdersCancelled from './ZbMixinOrdersCancelled'

export default {
  name: 'ZbOrders',
  components: {
    ZbInfoModal
  },
  mixins: [
    ZbMixinOrdersActive,
    ZbMixinOrdersCompleted,
    ZbMixinOrdersCancelled
  ],
  data () {
    return {
      tab: null,
      showInfoModal: false,
      selected: [],
      loading: false,
      orderInfo: null
    }
  },
  methods: {
    timeLeftOrder (dt) {
      const startdate = moment()
      const finishdate = moment(dt)
      const minutes = startdate.diff(finishdate, 'minutes')
      if (minutes === 0) return 'Hace segundos'
      return `${minutes} min.`
    },
    openInfoOrder (data) {
      if (!data) return
      this.orderInfo = data
      this.showInfoModal = true
    }
  }
}
