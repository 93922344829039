import { ServiceFactory } from '@/services/ServiceFactory'
const OrdersService = ServiceFactory.get('OrdersService')

export default {
  data () {
    return {
      dialogCompleted: false,
      provisionalIdBooking: null,
      provitionalAction: null,
      singleSelect: false,
      loadingCancelled: false,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      selected: [],
      headersCancelled: [
        { text: this.$t('orders.image'), sortable: false, value: 'zbClient.image' },
        { text: this.$t('orders.name'), sortable: false, value: 'zbClient.name' },
        { text: this.$t('orders.takeoutTime'), align: 'start', sortable: false, value: 'takeoutTime' },
        { text: this.$t('orders.totalOrder'), align: 'start', sortable: false, value: 'order.totalBill' },
        { text: this.$t('general.createdAt'), sortable: false, value: 'createdAt' },
        { text: this.$t('orders.moreinfoText'), sortable: false, value: 'order' }
      ],
      ordersCancelled: [],
      itemsPerPageCancelled: 15,
      pageCancelled: 1,
      optionsCancelled: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 2) {
        this.initializeTableOrdersCancelled()
      }
    },
    pageCancelled () {
      this.initializeTableOrdersCancelled()
    },
    itemsPerPageCancelled () {
      this.initializeTableOrdersCancelled()
    }
  },
  methods: {
    async initializeTableOrdersCancelled () {
      this.loadingCancelled = true
      const { orders, success, totalItems, totalPages, currentPage } = await OrdersService.get(this.pageCancelled, 'cancelled', this.itemsPerPageCancelled)
      this.loadingCancelled = false
      this.ordersCancelled = orders
      this.optionsCancelled = {
        success,
        totalItems,
        totalPages,
        currentPage
      }
    }
  }
}
