var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"secondary-30"}},[_c('v-tabs',{staticClass:"primary",attrs:{"background-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-clipboard")]),_c('span',[_vm._v(_vm._s(_vm.$t('tableOrders.active')))])],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-clipboard-check")]),_c('span',[_vm._v(_vm._s(_vm.$t('tableOrders.complete')))])],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-clipboard-times")]),_c('span',[_vm._v(_vm._s(_vm.$t('tableOrders.cancelled')))])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticClass:"secondary-30 px-2 py-2"},[_c('v-data-table',{staticClass:"elevation-1 secondary-25",attrs:{"headers":_vm.headersActive,"items":_vm.ordersActive,"item-key":"name","items-per-page":_vm.itemsPerPageActive,"server-items-length":_vm.optionsActive.totalItems,"page":_vm.pageActive,"loading":_vm.loadingActive},on:{"update:itemsPerPage":function($event){_vm.itemsPerPageActive=$event},"update:items-per-page":function($event){_vm.itemsPerPageActive=$event},"update:page":function($event){_vm.pageActive=$event}},scopedSlots:_vm._u([{key:"item.zbClient.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2"},[_c('img',{attrs:{"src":item.zbClient.image || _vm.publicPath,"alt":item.zbClient.name}})])]}},{key:"item.order.totalBill",fn:function(ref){
var item = ref.item;
return [_vm._v(" Bs. "+_vm._s(item.order.totalBill)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.dateString(item.date))+" ")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-2 mx-2",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openInfoOrder(item)}}},[_c('v-icon',[_vm._v("fas fa-info")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"secondary-30 px-2 py-2"},[_c('v-data-table',{staticClass:"elevation-1 secondary-25",attrs:{"headers":_vm.headersDelivered,"items":_vm.ordersDelivered,"item-key":"name","items-per-page":_vm.itemsPerPageDelivered,"server-items-length":_vm.optionsDelivered.totalItems,"page":_vm.pageDelivered,"loading":_vm.loadingDelivered},on:{"update:itemsPerPage":function($event){_vm.itemsPerPageDelivered=$event},"update:items-per-page":function($event){_vm.itemsPerPageDelivered=$event},"update:page":function($event){_vm.pageDelivered=$event}},scopedSlots:_vm._u([{key:"item.zbClient.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2"},[_c('img',{attrs:{"src":item.zbClient.image || _vm.publicPath,"alt":item.zbClient.name}})])]}},{key:"item.numberTable",fn:function(ref){
var item = ref.item;
return [_vm._v(" Mesa "+_vm._s(item.numberTable)+" ")]}},{key:"item.order.totalBill",fn:function(ref){
var item = ref.item;
return [_vm._v(" Bs. "+_vm._s(item.order.totalBill)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.dateString(item.date))+" ")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-2 mx-2",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openInfoOrder(item)}}},[_c('v-icon',[_vm._v("fas fa-info")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"secondary-30 px-2 py-2"},[_c('v-data-table',{staticClass:"elevation-1 secondary-25",attrs:{"headers":_vm.headersCancelled,"items":_vm.ordersCancelled,"item-key":"name","items-per-page":_vm.itemsPerPageCancelled,"server-items-length":_vm.optionsCancelled.totalItems,"page":_vm.pageCancelled,"loading":_vm.loadingCancelled},on:{"update:itemsPerPage":function($event){_vm.itemsPerPageCancelled=$event},"update:items-per-page":function($event){_vm.itemsPerPageCancelled=$event},"update:page":function($event){_vm.pageCancelled=$event}},scopedSlots:_vm._u([{key:"item.zbClient.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2"},[_c('img',{attrs:{"src":item.zbClient.image || _vm.publicPath,"alt":item.zbClient.name}})])]}},{key:"item.numberTable",fn:function(ref){
var item = ref.item;
return [_vm._v(" Mesa "+_vm._s(item.numberTable)+" ")]}},{key:"item.order.totalBill",fn:function(ref){
var item = ref.item;
return [_vm._v(" Bs. "+_vm._s(item.order.totalBill)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.dateString(item.date))+" ")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-2 mx-2",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openInfoOrder(item)}}},[_c('v-icon',[_vm._v("fas fa-info")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),(_vm.orderInfo)?_c('zb-info-modal',{attrs:{"orderInfo":_vm.orderInfo},on:{"reload":_vm.handleReload},model:{value:(_vm.showInfoModal),callback:function ($$v) {_vm.showInfoModal=$$v},expression:"showInfoModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }