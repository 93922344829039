import { ServiceFactory } from '@/services/ServiceFactory'
const OrdersService = ServiceFactory.get('OrdersService')

export default {
  name: 'ZbInfoModal',
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      infoData: null,
      acceptLoading: false,
      cancelLoading: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    orderInfo: {
      type: Object
    }
  },
  methods: {
    async changeState (state) {
      try {
        this.acceptLoading = true
        const { success } = await OrdersService.put(this.orderInfo.zbTakeoutOrderId, { orderState: state })
        this.acceptLoading = false
        if (success) {
          this.$emit('reload')
          this.$emit('input')
        }
      } catch (error) {
        this.acceptLoading = false
      }
    },
    async cancelOrder () {
      this.$swal({
        title: this.$t('orders.cancelQuestion'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('delete.confirm'),
        cancelButtonText: this.$t('delete.cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.cancelLoading = true
            const { success } = await OrdersService.delete(this.orderInfo.zbTakeoutOrderId, { orderState: 'cancelled' })
            this.cancelLoading = false
            if (success) {
              this.$emit('input')
              this.$emit('reload')
            }
          } catch (error) {
            this.cancelLoading = false
          }
        }
      })
    },
    dialogEvent () {
      this.$emit('input')
    }
  }
}
