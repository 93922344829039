import { ServiceFactory } from '@/services/ServiceFactory'
const OrdersService = ServiceFactory.get('OrdersService')

export default {
  data () {
    return {
      dialogCompleted: false,
      provisionalIdBooking: null,
      provitionalAction: null,
      singleSelect: false,
      loadingActive: false,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      selected: [],
      headersActive: [
        { text: this.$t('orders.image'), sortable: false, value: 'zbClient.image' },
        { text: this.$t('orders.name'), sortable: false, value: 'zbClient.name' },
        { text: this.$t('orders.takeoutTime'), align: 'start', sortable: false, value: 'takeoutTime' },
        { text: this.$t('orders.totalOrder'), align: 'start', sortable: false, value: 'order.totalBill' },
        { text: this.$t('general.state'), sortable: false, value: 'stateText' },
        { text: this.$t('general.createdAt'), sortable: false, value: 'createdAt' },
        { text: this.$t('orders.moreinfoText'), sortable: false, value: 'order' }
      ],
      ordersActive: [],
      itemsPerPageActive: 15,
      pageActive: 1,
      optionsActive: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 0) {
        this.initializeOrdersActive()
      }
    },
    pageActive () {
      this.initializeOrdersActive()
    },
    itemsPerPageActive () {
      this.initializeOrdersActive()
    }
  },
  methods: {
    async initializeOrdersActive () {
      this.loadingActive = true
      const { orders, success, totalItems, totalPages, currentPage } = await OrdersService.get(this.pageActive, 'waiting', this.itemsPerPageActive)
      this.loadingActive = false
      this.ordersActive = orders
      this.optionsActive = {
        success,
        totalItems,
        totalPages,
        currentPage
      }
    },
    handleReload () {
      this.initializeOrdersActive()
    }
  }
}
